//
//
//
//
//
//
//
//
//

import globalConfig from '~/config/global_config.json';

export default {
  name: 'DefaultUserAvatar',
  props: {
    color: {
      type: String,
      default: 'white',
      required: false
    }
  }
}
