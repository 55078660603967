export const CustomAdContent = () => import('../../components/placeholders/CustomAdContent.vue' /* webpackChunkName: "components/custom-ad-content" */).then(c => wrapFunctional(c.default || c))
export const D2Front = () => import('../../components/placeholders/D2Front.vue' /* webpackChunkName: "components/d2-front" */).then(c => wrapFunctional(c.default || c))
export const DNJobPortalFront = () => import('../../components/placeholders/DNJobPortalFront.vue' /* webpackChunkName: "components/d-n-job-portal-front" */).then(c => wrapFunctional(c.default || c))
export const DNTVFront = () => import('../../components/placeholders/DNTVFront.vue' /* webpackChunkName: "components/d-n-t-v-front" */).then(c => wrapFunctional(c.default || c))
export const DnNorkonInvestorNews = () => import('../../components/placeholders/DnNorkonInvestorNews.vue' /* webpackChunkName: "components/dn-norkon-investor-news" */).then(c => wrapFunctional(c.default || c))
export const DnRealEstateRobotFront = () => import('../../components/placeholders/DnRealEstateRobotFront.vue' /* webpackChunkName: "components/dn-real-estate-robot-front" */).then(c => wrapFunctional(c.default || c))
export const DnxFront = () => import('../../components/placeholders/DnxFront.vue' /* webpackChunkName: "components/dnx-front" */).then(c => wrapFunctional(c.default || c))
export const EmbedContent = () => import('../../components/placeholders/EmbedContent.vue' /* webpackChunkName: "components/embed-content" */).then(c => wrapFunctional(c.default || c))
export const EvervizEmbed = () => import('../../components/placeholders/EvervizEmbed.vue' /* webpackChunkName: "components/everviz-embed" */).then(c => wrapFunctional(c.default || c))
export const LPRecommendationFront1 = () => import('../../components/placeholders/LPRecommendationFront1.vue' /* webpackChunkName: "components/l-p-recommendation-front1" */).then(c => wrapFunctional(c.default || c))
export const LPRecommendationFront2 = () => import('../../components/placeholders/LPRecommendationFront2.vue' /* webpackChunkName: "components/l-p-recommendation-front2" */).then(c => wrapFunctional(c.default || c))
export const LPRecommendationFront2WithAd = () => import('../../components/placeholders/LPRecommendationFront2WithAd.vue' /* webpackChunkName: "components/l-p-recommendation-front2-with-ad" */).then(c => wrapFunctional(c.default || c))
export const LPRecommendationFront3 = () => import('../../components/placeholders/LPRecommendationFront3.vue' /* webpackChunkName: "components/l-p-recommendation-front3" */).then(c => wrapFunctional(c.default || c))
export const LiveCenters = () => import('../../components/placeholders/LiveCenters.vue' /* webpackChunkName: "components/live-centers" */).then(c => wrapFunctional(c.default || c))
export const LiveWrappedAd = () => import('../../components/placeholders/LiveWrappedAd.vue' /* webpackChunkName: "components/live-wrapped-ad" */).then(c => wrapFunctional(c.default || c))
export const MagasinetFront = () => import('../../components/placeholders/MagasinetFront.vue' /* webpackChunkName: "components/magasinet-front" */).then(c => wrapFunctional(c.default || c))
export const MeningerFront = () => import('../../components/placeholders/MeningerFront.vue' /* webpackChunkName: "components/meninger-front" */).then(c => wrapFunctional(c.default || c))
export const MostRead = () => import('../../components/placeholders/MostRead.vue' /* webpackChunkName: "components/most-read" */).then(c => wrapFunctional(c.default || c))
export const Podcast = () => import('../../components/placeholders/Podcast.vue' /* webpackChunkName: "components/podcast" */).then(c => wrapFunctional(c.default || c))
export const SmakFront = () => import('../../components/placeholders/SmakFront.vue' /* webpackChunkName: "components/smak-front" */).then(c => wrapFunctional(c.default || c))
export const WineSearch = () => import('../../components/placeholders/WineSearch.vue' /* webpackChunkName: "components/wine-search" */).then(c => wrapFunctional(c.default || c))
export const AdobeMbox = () => import('../../components/placeholders/adobeMbox.vue' /* webpackChunkName: "components/adobe-mbox" */).then(c => wrapFunctional(c.default || c))
export const DfpAd = () => import('../../components/placeholders/dfpAd.vue' /* webpackChunkName: "components/dfp-ad" */).then(c => wrapFunctional(c.default || c))
export const FantasyFond = () => import('../../components/placeholders/fantasyFond.vue' /* webpackChunkName: "components/fantasy-fond" */).then(c => wrapFunctional(c.default || c))
export const DNFooter = () => import('../../components/global/DNFooter.vue' /* webpackChunkName: "components/d-n-footer" */).then(c => wrapFunctional(c.default || c))
export const DNHeader = () => import('../../components/global/DNHeader.vue' /* webpackChunkName: "components/d-n-header" */).then(c => wrapFunctional(c.default || c))
export const BlockFrontPageBottomEdition = () => import('../../components/block/FrontPageBottomEdition.vue' /* webpackChunkName: "components/block-front-page-bottom-edition" */).then(c => wrapFunctional(c.default || c))
export const BlockFrontPageTopEdition = () => import('../../components/block/FrontPageTopEdition.vue' /* webpackChunkName: "components/block-front-page-top-edition" */).then(c => wrapFunctional(c.default || c))
export const ComponentDefaultUserAvatar = () => import('../../components/component/DefaultUserAvatar.vue' /* webpackChunkName: "components/component-default-user-avatar" */).then(c => wrapFunctional(c.default || c))
export const ComponentInvestorTicker = () => import('../../components/component/InvestorTicker.vue' /* webpackChunkName: "components/component-investor-ticker" */).then(c => wrapFunctional(c.default || c))
export const ComponentIpLoginPopUp = () => import('../../components/component/IpLoginPopUp.vue' /* webpackChunkName: "components/component-ip-login-pop-up" */).then(c => wrapFunctional(c.default || c))
export const ComponentLiveCentersPlaceholder = () => import('../../components/component/LiveCentersPlaceholder.vue' /* webpackChunkName: "components/component-live-centers-placeholder" */).then(c => wrapFunctional(c.default || c))
export const ComponentLoadSVG = () => import('../../components/component/LoadSVG.vue' /* webpackChunkName: "components/component-load-s-v-g" */).then(c => wrapFunctional(c.default || c))
export const ComponentMainMenu = () => import('../../components/component/MainMenu.vue' /* webpackChunkName: "components/component-main-menu" */).then(c => wrapFunctional(c.default || c))
export const ComponentMainMenuSegment = () => import('../../components/component/MainMenuSegment.vue' /* webpackChunkName: "components/component-main-menu-segment" */).then(c => wrapFunctional(c.default || c))
export const ComponentPlaceHolderAdapter = () => import('../../components/component/PlaceHolderAdapter.vue' /* webpackChunkName: "components/component-place-holder-adapter" */).then(c => wrapFunctional(c.default || c))
export const ComponentTopEditionShowOnTopPlaceholders = () => import('../../components/component/TopEditionShowOnTopPlaceholders.vue' /* webpackChunkName: "components/component-top-edition-show-on-top-placeholders" */).then(c => wrapFunctional(c.default || c))
export const ComponentUserMenu = () => import('../../components/component/UserMenu.vue' /* webpackChunkName: "components/component-user-menu" */).then(c => wrapFunctional(c.default || c))
export const ElementsButtonX = () => import('../../components/elements/ButtonX.vue' /* webpackChunkName: "components/elements-button-x" */).then(c => wrapFunctional(c.default || c))
export const WrapperLazilyMount = () => import('../../components/wrapper/LazilyMount.js' /* webpackChunkName: "components/wrapper-lazily-mount" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
