//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from 'vuex';
import DefaultUserAvatar from '@/components/component/DefaultUserAvatar';
import globalConfig from '~/config/global_config.json';
export default {
  name: 'UserMenu',
  components: {
    DefaultUserAvatar
  },

  data: () => ({
    ignoredFirstClick: false,
    companyLogin: {
      access: false,
      adminLink: '',
      adminLinkText: ''
    }
  }),
  errorCaptured: function(err) {
    console.error(`Caught error from children of UserMenu component:: ${err.message}`);
  },
  computed: {
    isLoggedInUser() {
      return this.loginStatus.isLoggedIn;
    },
    logoutStatusMessage() {
      return this.header.userMenu.logout.statusMessage;
    },
    loginMenu() {
      return this.header.userMenu.login;
    },
    logoutMenu() {
      if(process.client) {
        return {
          ...this.header.userMenu.logout,
          link: this.addTargetToUrl(this.header.userMenu.logout.link)
        }
      }
      return this.header.userMenu.logout;
    },
    nhstLogoutMenu() {
      if(process.client) {
        return {
          ...this.header.userMenu.nhstLogout,
          link: this.addTargetToUrl(this.header.userMenu.nhstLogout.link)
        }
      }
      return this.header.userMenu.nhstLogout;
    },
    bliAbonnent() {
      return this.header.userMenu.bliAbonnent;
    },
    linkGroup1() {
      return this.header.userMenu.linkGroup1
        .map(groupInfo => {
          groupInfo.activeLink = this.isLoggedInUser ? groupInfo.linkEnabledOnLoggedIn : groupInfo.linkEnabledOnNotLoggedIn;
          return groupInfo;
        });
    },
    linkGroup2() {
      return this.header.userMenu.linkGroup2;
    },
    loggedInUserFirstNameFirstChar() {
      const fName =  this.loginStatus.firstName || '';
      return fName.length > 0 ? fName[0] : '';
    },
    loggedInUserLastNameFirstChar() {
      const fName =  this.loginStatus.lastName || '';
      return fName.length > 0 ? fName[0] : '';
    },
    loggedInUserFullName() {
      let fullName = (this.loginStatus.firstName || '') + ' ' + (this.loginStatus.lastName || '');
      if (!this.showDefaultUserAvatar) {
        return  fullName;
      } else {
        return this.loggedInUserName;
      }
    },
    loggedInUserName() {
      return this.loginStatus.username;
    },
    showDefaultUserAvatar() {
      return this.loginStatus.firstName.trim().length === 0 && this.loginStatus.lastName.trim().length === 0;
    },
    subscriptionType() {
      if (this.loginStatus.isTotalSubscriber) {
        return 'Total-abonnent';
      }
      return this.loginStatus.isSubscriber ? 'Basis-abonnent' : 'Ikke abonnent'
    },
    ...mapState(['header', 'loginStatus']),
  },
  mounted() {
    this.handleLocalStorageData();
    window.addEventListener('sub-corporate-admin-access',(event) => {
      this.updateCompanyLogin(event.value);
    })
  },
  beforeDestroy() {
    window.removeEventListener('sub-corporate-admin-access',(event) => {
      this.updateCompanyLogin(event.value);
    });
  },
  methods: {
    clickedOut() {
      if (this.ignoredFirstClick === true) {
        this.$emit('hide-user-menu');
      }
      this.ignoredFirstClick = true;
    },
    sendLoginBtnClickedEvent(){
      const msgToSend = {
        type: 'nhst-login-click'
      };
      window.postMessage(msgToSend, window.location.origin);
    },
    addTargetToUrl(path){
      const url = new URL(path, `${window.top.location.protocol}//${window.top.location.hostname}`);
      url.searchParams.append('target', window.top.location.href);
      return url.toString();
    },
    handleLocalStorageData() {
      const storedData = localStorage.getItem('sub-corporate-admin-access');
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        this.updateCompanyLogin(parsedData);
      }
    },
    updateCompanyLogin(data)  {
      if (data && data.value && data.value.corporate_status) {
        this.companyLogin.access = data.value.corporate_status.access;
        this.companyLogin.adminLink = data.value.corporate_status.admin_link;
        this.companyLogin.adminLinkText = data.value.corporate_status.admin_text;

      }
    }
  }
}
