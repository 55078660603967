export default function ({ store, route}) {
  let view  = route.query.view || '';
  let isMobileView = false;
  if (view === 'noheader' || view === 'app') {
    isMobileView = true;
  }
  store.commit('setShowingOnMobile', isMobileView);
}


