//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import globalConfig from '~/config/global_config.json';

export default {
  name: 'MainMenuSegment',
  props: {
    type: {
      type: String,
      default: function () {
        return '';
      },
    },
    blocks: {
      type: Array,
      default: function () {
        return [];
      }
    },
    selectedBlockLabel: {
      type: String,
      default: function () {
        return '';
      }
    }
  },
  errorCaptured: function(err) {
    console.error(`Caught error from children of MainMenuSegment component:: ${err.message}`);
  },
  methods: {
    getNestedBlocks(block) {
      return (block && block.nestedBlocks) || [];
    },
    hasNestedBlocks(block) {
      return (block && Array.isArray(block.nestedBlocks) && block.nestedBlocks.length > 0);
    },
    mouseOverLeft(block) {
      if (this.type === 'left' && this.hasNestedBlocks(block)) {
        this.$emit('mouse-over-left');
      }
    },
    mouseOutLeft(block) {
      if (this.type === 'left' && this.hasNestedBlocks(block)) {
        this.$emit('mouse-out-left');
      }
    }
  }
}
