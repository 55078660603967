//
//
//
//
//
//
//
//
//
//

import DNHeader from '@/components/global/DNHeader';
import DNFooter from '@/components/global/DNFooter';
import LoadSVG from '@/components/component/LoadSVG';
import {mapState} from 'vuex';
import ScriptLoaderMixin from '@/components/mixin/ScriptLoaderMixin';

export default {
  components: {
    DNHeader,
    DNFooter,
    LoadSVG
  },
  mixins: [ScriptLoaderMixin],
  computed: {
    ...mapState(['isShowingOnMobileApp']),
    showHeaderFooter() {
      return !this.isShowingOnMobileApp;
    },
    getLayoutClassName() {
      if (this.showHeaderFooter) {
        return 'main-layout'
      } else {
        return 'main-layout--noheader'
      }
    }
  },
  errorCaptured: function(errorStackTrace, vueInstance, info) {
    console.error({
      message: 'Error Occured from Vue Component',
      errorStackTrace,
      vueInstance,
      info
    });

    //  return false;
  }
}
