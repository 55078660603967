const middleware = {}
/* eslint-disable dot-notation */

middleware['mobileAppView'] = require('../middleware/mobileAppView.js')
middleware['mobileAppView'] = middleware['mobileAppView'].default || middleware['mobileAppView']

middleware['skipRenderModule'] = require('../middleware/skipRenderModule.js')
middleware['skipRenderModule'] = middleware['skipRenderModule'].default || middleware['skipRenderModule']

/* eslint-enable dot-notation */
export default middleware
