//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from 'vuex';
import globalConfig from '~/config/global_config.json';

export default {
  name: 'DNFooter',
  data: () => ({
    isEditorBlockOpen: false,
    isContactBlockOpen: false,
  }),
  computed: {
    introduction() {
      return this.footer.introduction || {};
    },
    linkBlocks() {
      return this.footer.linkBlocks || [];
    },
    editorsGroup() {
      return this.footer.editorsGroup || [];
    },
    contacts() {
      return this.footer.contacts || [];
    },
    ...mapState(['footer', 'loginStatus']),
  },
  methods: {
    updateEditorBlockView() {
      this.isEditorBlockOpen = !this.isEditorBlockOpen;
      this.isContactBlockOpen = false;
    },
    updateContactBlockView() {
      this.isEditorBlockOpen = false;
      this.isContactBlockOpen = !this.isContactBlockOpen;
    },
    goto(path) {
      window.location.href = path;
    },
    getNestedBlocks(block) {
      return (block && block.nestedBlocks) || [];
    }
  }
}
