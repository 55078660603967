import {mapActions} from 'vuex';

export default {
  computed: {
  },
  methods: {
    ...mapActions([
      'setScriptLoadedStatus'
    ]),
    updateData(scriptName) {
      this.setScriptLoadedStatus(scriptName);
    }
  },
  created() {
    if (process.server) {
      return;
    }
    window.loadedScripts = window.loadedScripts || {};
    Object.keys(window.loadedScripts).forEach(scriptName => {
      this.updateData(scriptName);
    });
    window.loadedScripts = new Proxy(window.loadedScripts, {
      set: (target, prop, value) => {
        target[prop] = value;
        this.updateData(prop);
        return true;
      }
    });
  },
};
