export const state = () => ({
  topEditionResponse: {},
  bottomEditionPlaceHolders: [],
  placeholdersAds: {},
  nodeEnv: '',
  locals: {},
  adSlots: {},
  articleIdsToFilter: [],
  header: {},
  investorTicker: {},
  loginStatus: {
    isLoggedIn: false,
    username: '',
    firstName: '',
    lastName: '',
    ncpId: '',
    ncpIdHash: '',
    products: [],
    isSubscriber: false,
    isTotalSubscriber: false
  },
  footer: {},
  meta: {},
  isShowingOnMobileApp: false,
  skipModules: [],
  scriptLoaded: {
    satelliteScript: false
  },
});

export const mutations = {
  setFrontPageTopContent(state, content) {
    state.topEditionResponse = content;
  },
  addBottomEditionPlaceHolders(state, placeholder) {
    state.bottomEditionPlaceHolders.push(placeholder);
  },
  setNodeEnv(state, nodeEnv) {
    state.nodeEnv = nodeEnv;
  },
  setLocals(state, locals) {
    state.locals = locals;
  },
  setAdSlots(state, slots) {
    state.adSlots = slots;
  },
  setPlaceholdersAds(state, placeholdersAds) {
    state.placeholdersAds = placeholdersAds;
  },
  addToFilterArticleIds(state, ids) {
    state.articleIdsToFilter.push(...ids);
  },
  addArticlesToLPPlaceholder(state, {index, articles, adobeSegment}) {
    state.bottomEditionPlaceHolders[index].adobeSegment = adobeSegment
    state.bottomEditionPlaceHolders[index].articles.push(...articles);
  },
  setHeaderData(state, header) {
    state.header = header;
  },
  setInvestorTicker(state, investorTicker) {
    state.investorTicker = investorTicker;
  },
  setLoginStatus(state, {isLoggedIn = false, username = '', firstName = '', lastName = '', ncpId = '', ncpIdHash = '', products = [], isSubscriber= false, isTotalSubscriber = false}) {
    state.loginStatus.isLoggedIn = isLoggedIn;
    state.loginStatus.username = username;
    state.loginStatus.firstName = firstName;
    state.loginStatus.lastName = lastName;
    state.loginStatus.ncpId = ncpId;
    state.loginStatus.ncpIdHash = ncpIdHash;
    state.loginStatus.products = products;
    state.loginStatus.isSubscriber = isSubscriber;
    state.loginStatus.isTotalSubscriber = isTotalSubscriber;
  },
  setFooterData(state, footer) {
    state.footer = footer;
  },
  setMetaData(state, meta) {
    state.meta = meta;
  },
  setShowingOnMobile(state, isShowing) {
    state.isShowingOnMobileApp = isShowing
  },
  setSkipModules(state, skipModules) {
    state.skipModules = skipModules;
  },
  setScriptLoadedStatus(state, scriptName) {
    if (state.scriptLoaded[scriptName] === false) {
      state.scriptLoaded[scriptName] = true;
    }
  },
  deleteFrontPageTopCssContent(state) {
    if(state.topEditionResponse && state.topEditionResponse.frontpageTopCssContent) {
      delete state.topEditionResponse.frontpageTopCssContent;
    }
  }
};

export const actions = {
  async getEditionContent({ commit }, category) {
    try {
    let response = await this.$axios.$get(`/nhst-front/api/edition/${category}`);
    const topEditionResponse = response.topEditionResponse || {};
    commit('setFrontPageTopContent', topEditionResponse);
    commit('addToFilterArticleIds', topEditionResponse.articleIds || []);
    const {
      bottomEditionResponse: { bottomEditionPlaceHolders = [] },
    } = response;

    bottomEditionPlaceHolders.forEach((placeholder) => {
      placeholder.articles = placeholder.articles || [];
      placeholder.placeholderType = placeholder.placeholderType || 'frontpage-bottom';
      commit('addBottomEditionPlaceHolders', placeholder || {});
      commit('addToFilterArticleIds', placeholder.articleIds || []);
    });
    commit('setNodeEnv', response.commonResponse.nodeEnv);
    commit('setLocals', response.commonResponse.locals);
    commit('setPlaceholdersAds', response.commonResponse.placeholdersAds);
    commit('setHeaderData', response.commonResponse.header);
    commit('setFooterData', response.commonResponse.footer);
    commit('setInvestorTicker', response.commonResponse.investorTicker);
    commit('setMetaData', response.meta);
    } catch (error) {
      console.error(`something went wrong while fetching /api/edition/${category} error:: ${error} `);
    }
  },
  setAdSlots({ commit }, slots) {
    commit('setAdSlots', slots);
  },
  addToFilterArticleIds({ commit }, ids) {
    commit('addToFilterArticleIds', ids);
  },
  addArticlesToLPPlaceholder({ commit }, { index, articles, adobeSegment }) {
    commit('addArticlesToLPPlaceholder', { index, articles, adobeSegment});
  },
  setLoginStatus({ commit }, payload) {
    commit('setLoginStatus', payload);
  },
  setScriptLoadedStatus({ commit }, scriptName) {
    commit('setScriptLoadedStatus', scriptName);
  }
}
