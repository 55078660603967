//
//
//
//

export default {
  data() {
    return {
      svgContent: ''
    };
  },
  mounted() {
    this.loadSVG();
  },
  methods: {
    loadSVG() {
      fetch(`${process.env.APP_CDN_URL ? process.env.APP_CDN_URL : ''}/nhstfront/sprite.svg?v=1.1`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.text();
        })
        .then(data => {
          this.svgContent = data;
        })
        .catch(error => {
          console.error('Error loading SVG:', error);
        });
    }
  }
}
