//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from 'vuex';

export default {
  name: 'MainMenu',
  data: () => ({
    selectedBlockLabel: '',
    mouseOverLeftSegment: false,
  }),
  errorCaptured: function(err) {
    console.error(`Caught error from children of MainMenu Component:: ${err.message}`);
  },
  computed: {
    ...mapState(['header']),
  },
  methods: {
    updateSelectedBlockLabel(label) {
      this.selectedBlockLabel = this.selectedBlockLabel === label ? '' : label;
    }
  }
}
