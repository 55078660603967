//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from 'vuex';
import _ from 'lodash';
import globalConfig from '~/config/global_config.json';

export default {
  name: 'GlobalHeader',
  data: () => ({
    lastScrollY: 0,
    isScrollingUp: false,
    isScrollingDown: false,
    simplifiedHeaderThreshold: 300,
    minCountableScrollChange: 50,
    scrollingUp: false,
    scrollingDown: false,
    scrollingFromTop: false,
    relativeLowScrollY: 0,
    showMainMenu: false,
    showUserMenu: false,
    searchText: '',
    ignoredFirstClick: false,
    alreadyMounted: false,
  }),
  computed: {
    time() {
      return this.header.time || '';
    },
    isLoggedInUser() {
      return this.loginStatus.isLoggedIn;
    },
    loggedInUserFirstNameFirstChar() {
      const fName =  this.loginStatus.firstName || '';
      return fName.length > 0 ? fName[0] : '';
    },
    loggedInUserLastNameFirstChar() {
      const fName = this.loginStatus.lastName || '';
      return fName.length > 0 ? fName[0] : '';
    },
    shouldShowAbonnerButton() {
      if (this.alreadyMounted) {
        return this.loginStatus.isLoggedIn ? !this.loginStatus.isSubscriber : true;
      }
      return  false;
    },
    shouldShowLoginButton() {
      return this.alreadyMounted;
    },
    showDefaultUserIcon() {
      if (this.alreadyMounted) {
        return  this.loggedInUserFirstNameFirstChar.length === 0 && this.loggedInUserLastNameFirstChar.length === 0;
      }
      return false
    },
    ...mapState(['header', 'loginStatus']),
  },
  /*head() {
    return {
      htmlAttrs: {
        class: this.showMainMenu || this.showUserMenu ? 'overflow-hidden is-flyout-open' : ''
      },
      bodyAttrs: {
        class: this.showMainMenu || this.showUserMenu ? 'overflow-hidden' : ''
      }
    }
  },*/
  created() {
    if (process.client) {
      window.addEventListener('scroll', this.handleScroll);
    }
  },
  destroyed() {
    if (process.client) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  },
  mounted() {
    this.alreadyMounted = true;
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    handleScroll: _.throttle(function (event) {
      const currentScrollPosition = window.scrollY || document.documentElement.scrollTop;
      const secondLastScrollY = this.lastScrollY;

      this.scrollingFromTop = this.lastScrollY < this.simplifiedHeaderThreshold && currentScrollPosition >= this.simplifiedHeaderThreshold;
      this.isScrollingUp = currentScrollPosition <= this.lastScrollY;
      this.isScrollingDown = currentScrollPosition >= this.lastScrollY;
      this.lastScrollY = currentScrollPosition <= 0 ? 0 : currentScrollPosition;
      if (this.lastScrollY === 0) {
        this.scrollingUp = false;
        this.scrollingDown = false;
        return;
      }
      if (Math.abs(this.lastScrollY - secondLastScrollY) < this.minCountableScrollChange) {
        return;
      }
      this.scrollingUp = this.lastScrollY > this.simplifiedHeaderThreshold && this.isScrollingUp === true;
      this.scrollingDown = this.lastScrollY > this.simplifiedHeaderThreshold && this.isScrollingDown === true;
    }, 200),
    doDnSearch() {
      window.location.href = `/sok?q=${encodeURIComponent(this.searchText)}`;
    },
    scrollTop () {
      const topscrollAd = document.querySelectorAll('.adnm-topscroll:not(.adnm-topscroll-fixed)') || '';
      if (topscrollAd.length > 0) {
        window.scrollTo(0, 0);
        document.querySelector('html').classList.add('adnm-topscroll-hide');
      }
    },
    scrollNormal () {
      const topscrollAdFixed = document.querySelectorAll('.adnm-topscroll-fixed') || '';
      const topHeader = document.querySelectorAll('.header:not(.header-sticky)') || '';
      if (topscrollAdFixed.length > 0) {
        if(topHeader.length > 0) {
          window.scrollTo(0, 1);
        }
        document.querySelector('html').classList.remove('adnm-topscroll-hide');
      } else {
        document.querySelector('html').classList.remove('adnm-topscroll-hide');
      }
    },
    addHtmlBodyClass () {
      document.querySelector('html').classList.add('overflow-hidden', 'is-flyout-open');
      document.querySelector('body').classList.add('overflow-hidden');
      this.scrollTop ();
    },
    removeHtmlBodyClass () {
      document.querySelector('html').classList.remove('overflow-hidden', 'is-flyout-open');
      document.querySelector('body').classList.remove('overflow-hidden');
      this.scrollNormal ();
    },
    displayUserMenu() {
      this.showMainMenu = false;
      this.showUserMenu = true;
      this.addHtmlBodyClass ();
    },
    toggleUserAndMainMenu() {
      this.hideUserMenu();
      this.toggleMainMenu();
    },
    toggleMainMenu() {
      this.showMainMenu = !this.showMainMenu;
      if (this.showMainMenu) {
        this.addHtmlBodyClass ();
      } else {
        this.removeHtmlBodyClass ();
      }
    },
    hideMainMenu() {
      this.showMainMenu = false;
      this.removeHtmlBodyClass ();
    },
    hideUserMenu() {
      this.showUserMenu = false;
      this.removeHtmlBodyClass ();
    },
    clickedOut() {
      this.showMainMenu = false;
      this.removeHtmlBodyClass ();
    },
    goto(path) {
      window.location.href = path;
    }
  }
}
